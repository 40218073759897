<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100 pb-32">
      <admin-navbar />
      <div class="px-4 md:px-10 mx-auto w-full">
        <div :class="{'grid': hasStatBlock}" class="lg:grid-cols-12 gap-2 pt-24">
          <div :class="{'lg:col-span-8': hasStatBlock}" class="px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
            >
              <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-between gap-4">
                  <div class="text-blueGray-700 text-xl font-bold flex items-center justify-start gap-2">
                    <table-dropdown class="flex justify-center"
                                    :actions="actions(subject)" />
                    <h6>
                      Entity page
                    </h6>
                  </div>
                  <div class="flex items-end justify-center gap-4"
                       v-if="isSaving">
                    <Loader class="transform scale-50" flex="flex justify-end items-end" margin=""
                            loader="width: 40px; height: 40px"
                            style="height: 30px" />
                    <span class="text-xs">Saving...</span>
                  </div>
                  <router-link
                      :to="{name: 'Table', params: {dbName: this.currentTable.db, tableName: this.currentTable.url}}"
                      class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                  >
                    Back
                  </router-link>
                </div>
              </div>
              <div v-if="Object.keys(entity).length && !Object.values(entity).includes(undefined)"
                   class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Information
                </h6>
                <div class="flex flex-wrap">
                  <div v-for="(field, index) in fields" :key="'entityField'+index"
                       class="w-full px-4 mt-6"
                       :class="['bigtext', 'image', 'youtube', 'markdown', 'json',
                       'selectlist', 'list', 'youtubelist', 'dict', 'files'].includes(field.type)
                        ? 'lg:w-12/12' : 'lg:w-6/12'">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        <span>
                          {{ field.verbose ? field.verbose : field.name }}
                        </span>
                        <span class="wb-text-red" v-if="field.flags && field.flags.includes('required')">*</span>
                      </label>
                        <!--starting-->
                      <div v-if="field.type === 'bigtext'">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <textarea class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  :placeholder="field.placeholder ? field.placeholder : false"
                                  :rows="field.rows ? field.rows : 4"
                                  v-model="entity[field.name]"
                        ></textarea>
                      </div>
                      <div v-else-if="field.type === 'image'">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <div @click="collapsePlace(['collapse'], field.name, 'max-h-0')"
                             class="cursor-pointer border p-3">
                          <img :src="entity[field.name]" alt="..."
                               class="max-w-120-px">
                          <div class="mt-5">
                            Click
                          </div>
                        </div>
                        <vue-anka-cropper
                            :id="'collapse' + field.name"
                            class="w-full max-h-0 overflow-hidden transition-all mt-4"
                            :options="{
                                 aspectRatio: 1,
                                 closeOnSave: true,
                                 cropArea: 'circle',
                                 croppedHeight: 400,
                                 croppedWidth: 400,
                                 cropperHeight: false,
                                 frameLineDash: [5,3],
                                 frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                                 handleFillColor: 'rgba(255, 255, 255, 0.2)',
                                 handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                                 handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                                 handleSize: 6,
                                 handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                                 layoutBreakpoint: 850,
                                 maxCropperHeight: 768,
                                 maxFileSize: 16000000,
                                 overlayFill: 'rgba(0, 0, 0, 0.5)',
                                 previewOnDrag: true,
                                 previewQuality: 0.65,
                                 resultQuality: 0.8,
                                 resultMimeType: 'image/jpeg',
                                 selectButtonLabel: 'Upload picture',
                                 dropareaMessage: 'Select .png, .jpg or .jpeg file',
                                 showPreview: false,
                                 skin: 'weboa',
                                 uploadData: {},
                                 uploadTo: false}"
                            @cropper-error="ankaError"
                            @cropper-file-selected="onAnkaOpened"
                            @cropper-saved="onAnkaSaved($event, field.name)"></vue-anka-cropper>
                      </div>
                      <div v-else-if="field.type === 'files'">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <div class="border p-3">
                          <div class="relative mt-5"
                               v-for="(file, fileIndex) in strToJson(entity[field.name])" :key="'file' + fileIndex">
                            <a download="" target="_blank" :href="file" class="wb-text-blue">
                              {{file}}
                            </a>
                            <img v-if="['svg', 'jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'tiff', 'tif', 'bmp']
                                      .includes(file.split('.').pop())
                                       && (!subentity[field.name]
                                      || (subentity[field.name] && subentity[field.name] !== '{{uploading}}'))"
                                 :src="file" alt="..."
                                 class="max-w-120-px mt-5">

                            <div class="inline-flex absolute top-0 -left-10">
                              <Button @wasClicked="removeDictMeta(field.name, fileIndex)"
                                      size="0"
                                      class="p-3"
                                      colors="bg-red-500 text-white active:bg-red-600">
                                X
                              </Button>
                            </div>
                          </div>
                          <Loader v-if="subentity[field.name] && subentity[field.name] === '{{uploading}}'" />

                          <Button
                              class="border border-dashed tt-border-gray py-3 cursor-pointer
                            inline-flex items-center justify-center flex-col gap-2 relative mt-5">
                            <span>Upload</span>
                            <input type="file" class="cursor-pointer absolute top-0 z-10 left-0 w-full h-full opacity-0"
                                   multiple
                                   @change="changeCallback($event, field.name)">
                          </Button>
                        </div>

                      </div>
                      <div v-else-if="field.type === 'youtube'" class="mt-2 mb-8">
                        <input :type="field.type"
                               :placeholder="field.placeholder ? field.placeholder : false"
                               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                               v-model="entity[field.name]" />
                        <label v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</label>

                        <a :href="youtube_url(entity[field.name])"
                           target="_blank" class="block wb-text-gray text-xs mt-8">
                          {{youtube_url(entity[field.name])}}
                        </a>
                        <iframe width="560" height="315" :src="youtube_url_frame(entity[field.name])"
                                title="YouTube video player" frameborder="0" class="mt-2"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                      <div v-else-if="field.type === 'select'">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <select v-model="entity[field.name]"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                          <option v-for="(option, indexOption) in selectors[internalOrNotSelector(field.select)]"
                                  :key="'indexOption'+indexOption"
                                  :value="option.name"
                                  :selected="entity[field.name]===option.name">
                            {{option.verbose}}
                          </option>
                        </select>
                      </div>
                      <div v-else-if="field.type === 'markdown'">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <Markdown
                            @changed="updateValue($event, entity, field.name)"
                            :data="toBlockEditor(entity[field.name])"
                            :placeholder="field.placeholder ? field.placeholder : false"
                            :holder="'indexField' + index" />
                      </div>
                      <div v-else-if="field.type === 'json'">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <JsonTextarea @changed="updateValue($event, entity, field.name)"
                                      :data="prettyJson(entity[field.name])" />
                      </div>
                      <div v-else-if="field.type === 'selectlist'"
                           class="flex items-start justify-center gap-4 flex-col">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>

                        <div class="w-full relative"
                             :key="'el'+elementIndex+wasDeleted"
                             v-for="(element, elementIndex) in strToJson(entity[field.name])">
                          <div class="inline-flex absolute -left-10">
                            <Button @wasClicked="removeDictMeta(field.name, elementIndex)"
                                    size="0"
                                    class="p-3"
                                    colors="bg-red-500 text-white active:bg-red-600">
                              X
                            </Button>
                          </div>
                          <select @change="updatedSelectorList($event, field.name, elementIndex)"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option :value="selLement[field.list.column]"
                                    :selected="element.toString() === selLement[field.list.column].toString()"
                                    :key="'selLementIndex' + selLementIndex"
                                    v-for="(selLement, selLementIndex) in getMySelectorsLists(field, element, field.list.unique)">
                              {{ selLement[field.list.output] }}
                            </option>
                          </select>
                        </div>
                        <div class="inline-flex justify-start mt-4">
                          <Button @wasClicked="addDictMeta(field)"
                                  v-if="!field.list.unique
                                  || (field.list.unique
                                  && strToJson(entity[field.name]).length !== getMySelectorsLists(field).length)"
                                  colors="bg-emerald-500 text-white active:bg-emerald-600">
                            Add
                          </Button>
                        </div>

                      </div>
                      <div v-else-if="field.type === 'list'"
                           class="flex items-start justify-center gap-4 flex-col">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>

                        <div class="w-full relative"
                             :key="'el'+elementIndex+wasDeleted"
                             v-for="(element, elementIndex) in strToJson(entity[field.name])">
                          <div class="inline-flex absolute -left-10">
                            <Button @wasClicked="removeDictMeta(field.name, elementIndex)"
                                    size="0"
                                    class="p-3"
                                    colors="bg-red-500 text-white active:bg-red-600">
                              X
                            </Button>
                          </div>
                          <Input type="text"
                                 @input="updatedDictMeta($event, field.name, elementIndex)"
                                 :value="element"
                          />
                        </div>
                        <div class="inline-flex justify-start mt-4">
                          <Button @wasClicked="addDictMeta(field)"
                                  colors="bg-emerald-500 text-white active:bg-emerald-600">
                            Add
                          </Button>
                        </div>

                      </div>
                      <div v-else-if="field.type === 'youtubelist'"
                           class="flex items-start justify-center gap-4 flex-col">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>

                        <div class="w-full relative"
                             :key="'el'+elementIndex+wasDeleted"
                             v-for="(element, elementIndex) in strToJson(entity[field.name])">
                          <div class="inline-flex absolute -left-10">
                            <Button @wasClicked="removeDictMeta(field.name, elementIndex)"
                                    size="0"
                                    class="p-3"
                                    colors="bg-red-500 text-white active:bg-red-600">
                              X
                            </Button>
                          </div>
                          <Input type="text"
                                 :placeholder="field.placeholder ? field.placeholder : false"
                                 @input="updatedDictMeta($event, field.name, elementIndex)"
                                 :value="element"
                          />
                          <a :href="youtube_url(element)"
                             target="_blank" class="block wb-text-gray text-xs mt-8">
                            {{youtube_url(element)}}
                          </a>
                          <iframe width="560" height="315" :src="youtube_url_frame(element)"
                                  title="YouTube video player" frameborder="0" class="mt-2"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div class="inline-flex justify-start mt-4">
                          <Button @wasClicked="addDictMeta(field)"
                                  colors="bg-emerald-500 text-white active:bg-emerald-600">
                            Add
                          </Button>
                        </div>

                      </div>
                      <div v-else-if="field.type === 'dict'"
                           class="flex flex-col items-start justify-center gap-2 xl:gap-4">
                        <div v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</div>
                        <div class="grid grid-cols-2 gap-2 xl:gap-4 relative"
                            v-for="(metaValue, metaIndex) in strToJson(entity[field.name])"
                             :key="'meta'+metaIndex+wasDeleted">
                          <div class="inline-flex absolute -left-10">
                            <Button @wasClicked="removeDictMeta(field.name, metaIndex)"
                                    size="0"
                                    class="p-3"
                                    colors="bg-red-500 text-white active:bg-red-600">
                              X
                            </Button>
                          </div>
                          <Input type="text"
                                 @input="updatedDictMeta($event, field.name, metaIndex, 'key')"
                                 :value="metaValue.key"
                          />
                          <Input type="text"
                                 @input="updatedDictMeta($event, field.name, metaIndex, 'value')"
                                 :value="metaValue.value"
                          />
                        </div>
                        <div class="inline-flex justify-start">
                          <Button @wasClicked="addDictMeta(field, 'dict')"
                                  colors="bg-emerald-500 text-white active:bg-emerald-600">
                            Add
                          </Button>
                        </div>
                      </div>
                      <div v-else-if="field.type === 'password'">
                        <input type="password"
                               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                               :placeholder="field.placeholder ? field.placeholder : false"
                               v-model="entity[field.name]"
                        />
                        <label v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</label>
                      </div>
                      <input v-else-if="field.type === 'checkbox'"
                             type="checkbox"
                             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                             v-model="entity[field.name]"
                      />
                      <div v-else-if="field.type === 'date'">
                        <DatePicker
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    :placeholder="field.placeholder ? field.placeholder : false"
                                    v-model="entity[field.name]"
                                    format="DD-MM-YYYY"
                                    value-type="format"
                                />
                        <label v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</label>
                      </div>
                      <div v-else>
                        <input type="text"
                               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                               :placeholder="field.placeholder ? field.placeholder : false"
                               v-model="entity[field.name]"
                        />
                        <label v-if="field.prompt" class="my-3 text-xs">{{field.prompt}}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <Button v-if="this.currentEntity === 'new'" class="mt-6" @wasClicked="createEntity()">
                  Create
                </Button>
              </div>
              <Loader v-else />
            </div>
          </div>
          <div v-if="hasStatBlock" class="lg:col-span-4 px-4">
            <CardProfile v-if="Object.keys(stat).length" :stat="stat" />
            <Loader v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import CardProfile from "@/components/Cards/CardProfile";
import vueAnkaCropper from 'vue-anka-cropper'
import collapse from '@/helpers/collapse'
import Loader from "@/components/Block/Loader";
import Button from "@/components/Inter/Button";
import Input from "@/components/Inter/Input";
import TableDropdown from "@/components/Dropdowns/TableDropdown";
import {listActions} from "@/helpers/userActions";
import nvgpost from "@/nvgpost";
import Markdown from "@/components/Inter/Markdown";
import JsonTextarea from "@/components/Inter/JsonTextarea";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  name: "EntityEdit",
  components: {
    Markdown,
    Loader, Button, JsonTextarea, Input, DatePicker,
    Sidebar, AdminNavbar, CardProfile, TableDropdown, vueAnkaCropper
  },
  computed: {
    currentTable: function () {
      if(this.$store.getters.currentTable)
        return this.$store.getters.currentTable
      else
        return {}
    },
    currentEntity: function () {
      return this.$route.params.entityId
    },
    fields: function () {
      return this.$store.getters.currentTable.actionData ? 
          this.$store.getters.currentTable.actionData.entity.fields.filter((e) => !e.hidden) : []
    },
    selectors: function () {
      return this.$store.state.selectors
    },
    selectorsLists: function () {
      return this.$store.state.selectorsLists
    },
    subject: function () {
      return this.$store.state.tableData[this.currentTable.url] ?
          this.$store.state.tableData[this.currentTable.url].filter(function (x) {
            return x.id.toString() === this.currentEntity.toString()
          }.bind(this))[0] : {}
    },
    countOfAnka: function () {
      return document.querySelectorAll(".ankaCropper__fileInput").length
    },
    actions: function () {
      return function (subject) {
        return listActions(this, subject)
      }
    },
    hasStatBlock: function () {
      return this.currentEntity !== 'new' && this.currentTable && this.currentTable.actionData && this.currentTable.actionData.stat
    }
  },
  data() {
    return {
      entity: {},
      subentity: {},
      isSaving: false,
      stat: {},
      wasDeleted: 0,
    }
  },
  mounted() {
    this.$store.commit("toggleTableDataPage")
    setTimeout(function () {
      if(!this.$store.state.tableDataPage) {
        this.$store.commit("toggleTableDataPage")
      }

      if(this.currentEntity === "new" && this.fields.filter(e => e.default).length) {
        for(const i in this.fields) {
          if(this.fields[i].default) {
            let resultDefault = this.fields[i].default

            if(resultDefault.length > 5 && resultDefault.slice(0,2) === "{{" && resultDefault.slice(-2) === "}}") {
              if(resultDefault.slice(2,-2) === "date_today") {
                const today = new Date()
                const yyyy = today.getFullYear()
                let mm = today.getMonth() + 1
                let dd = today.getDate()
                if (dd < 10) dd = '0' + dd
                if (mm < 10) mm = '0' + mm
                resultDefault = dd + '-' + mm + '-' + yyyy
              }
            }

            this.entity[this.fields[i].name] = resultDefault
          }
        }
      }
    }.bind(this), 500)
  },
  watch: {
    subject: function (newValue, oldValue) {
      if(newValue && Object.keys(newValue).length)
        this.statEntity()

      if(!Array.isArray(newValue) && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if(this.currentEntity === "new") {
          for(let i = 0; i < this.fields.length; i++) {
            this.entity[this.fields[i].name] = ""
            this.entity = Object.assign({}, this.entity)
          }
        }
        else {
          for(let i = 0; i < this.fields.length; i++) {
            if(newValue && this.entity[this.fields[i].name] !== newValue[this.fields[i].name])
            {
              this.entity[this.fields[i].name] = newValue[this.fields[i].name]
              this.entity = Object.assign({}, this.entity)
            }
          }
        }
      }
    },
    entity: {
      handler(newValue, oldValue) {
        newValue = Object.assign({}, newValue)

        if(!this.checkToUndefinedOrNull(oldValue) && !this.checkToUndefinedOrNull(newValue)
            && Object.values(newValue).length === Object.values(oldValue).length)
        {
          if(this.currentEntity !== "new") {
            this.isSaving = true
            this.$store.dispatch("saveTableData", {
              point: this.currentTable,
              subject: this.subject,
              setBy: "id",
              data: newValue,
              that: this,
            }).then(function () {
              this.isSaving = false
            }.bind(this))
          }
        }
      },
      deep: true
    },
    currentTable: function (newValue, oldValue) {
      if(oldValue.name === undefined) {
        this.$store.dispatch("getTableData", {
          table: newValue.name,
          output: newValue.url,
          that: this,
        })
      }
    },
    countOfAnka: function () {
      let ankas = document.querySelectorAll(".ankaCropper__fileInput")
      for (let i = 0; i < ankas.length; i++) {
        ankas[i].setAttribute("accept", ".jpg, .jpeg, .png")
        // todo: webp only
      }
    }
  },
  methods: {
    collapsePlace: collapse.collapsePlace,
    prettyJson: function(obj) {
      try {
        return JSON.stringify(JSON.parse(obj), null, 4)
      }
      catch (e) {
        return JSON.stringify(obj, null, 4)
      }
    },
    changeCallback: function (event, fieldName) {
      let selectedFiles = event.target.files
      let arrayFiles = []
      let selectedFilesDone = 0
      this.subentity[fieldName] = "{{uploading}}"
      this.subentity = Object.assign({}, this.subentity)

      for(let i = 0; i < selectedFiles.length; i++) {
        let selectedFile = selectedFiles[i]
        let reader = new FileReader()
        reader.onload = async function(event) {
          arrayFiles.push(event.target.result)
          selectedFilesDone++
          if(selectedFilesDone === selectedFiles.length) {
            await nvgpost(this.$apiEndpoint+"/v1/files",
                "POST",
                {
                  files: arrayFiles,
                },
                {}, (res) => {
                  if(res.data && res.data.length) {
                    if(this.entity[fieldName] && this.entity[fieldName].length && this.entity[fieldName] !== '""') {
                      this.entity[fieldName] = this.strToJson(this.entity[fieldName]).concat(res.data)
                    }
                    else {
                      this.entity[fieldName] = res.data
                    }
                    delete this.subentity[fieldName]
                    this.subentity = Object.assign({}, this.subentity)
                    this.$root.$emit("alert", "File uploaded successfully!", 1)
                  }
                  else {
                    this.$root.$emit("alert", "File upload Error", 2)
                  }
                }, true)
          }
        }.bind(this)
        setTimeout(function(){
          reader.readAsDataURL(selectedFile)
        }.bind(this),150)
      }
    },
    strToJson: function (e) {
      if(typeof e === "object")
        return e
      
      try {
        return JSON.parse(e)
      } catch (es) {
        return []
      }
    },
    checkToUndefinedOrNull: function (val) {
      if(val) {
        const fullLen = Object.values(val).length
        const undefinedLen = Object.values(val).filter((e) => e === undefined || e === null).length
        return fullLen === undefinedLen
      }

      return true
    },
    getMySelectorsLists: function (field, element, unique) {
      const classification = function (field) {
        return field.list && field.list.class
            ? this.selectorsLists.filter((e) => e[field.list.class] === field.where
                .filter((e2) => e2.column === field.list.class)[0].value )
            : this.selectorsLists
      }.bind(this)

      const getAvailable = function (list, field, element, unique) {
        if(list[0] && unique)
        {
          const includedList = this.strToJson(this.entity[field.name]).map((e) => e.toString())
          return list.filter((e) => !includedList.includes(e[field.list.column].toString())
              || element.toString() === e[field.list.column].toString() )
        }
        else
          return list
      }.bind(this)

      return getAvailable(classification(field), field, element, unique)
    },
    updatedSelectorList: function (event, fieldName, metaIndex) {
      let oldValue = this.strToJson(this.entity[fieldName])
      let res = event.target.value
      res = /^-?\d+$/.test(res) ? parseFloat(res) : res
      oldValue[metaIndex] = res
      this.entity[fieldName] = JSON.stringify(oldValue)
    },
    internalOrNotSelector: function (fieldSelect) {
      return fieldSelect.split(".")[0]
    },
    updateValue: function (res, entity, key) {
      entity[key] = res
    },
    updatedDictMeta: function (event, fieldName, metaIndex, keyOrValue) {
      let oldValue = this.strToJson(this.entity[fieldName])
      const res = /^-?\d+$/.test(event) ? parseFloat(event) : event
      if(keyOrValue)
        oldValue[metaIndex][keyOrValue] = res
      else
        oldValue[metaIndex] = res
      this.entity[fieldName] = JSON.stringify(oldValue)
    },
    addDictMeta: function (field, typeobj) {
      const fieldName = field.name
      this.wasDeleted++
      let oldValue = this.strToJson(this.entity[fieldName])
      if(!oldValue) {
        oldValue = []
      }

      if(typeobj === "dict") {
        oldValue.push({
          key: "",
          value: ""
        })
      }
      else {
        const defaultValue = this.getMySelectorsLists(field)[0]
        oldValue.push(defaultValue.id)
      }
      this.entity[fieldName] = JSON.stringify(oldValue)
    },
    removeDictMeta: function (fieldName, metaIndex) {
      this.wasDeleted++
      let oldValue = this.strToJson(this.entity[fieldName])
      oldValue = oldValue.filter((e, index) => index !== metaIndex)
      if(!oldValue || !oldValue.length) {
        this.entity[fieldName] = ""
      }
      else
        this.entity[fieldName] = JSON.stringify(oldValue)
    },
    toBlockEditor: function (entityField) {
      try {
        return this.strToJson(entityField)["json"]
      } catch (e) {
        return {blocks: [
            {
              type: 'paragraph',
              data: {
                text: entityField
              }
            }
          ]}
      }
    },
    youtube_url: function (code) {
      const youtube = "https://youtu.be/"
      if(!code)
        return youtube

      return code.indexOf(youtube) !== -1 ? code : "https://www.youtube.com/embed/" + code
    },
    youtube_url_frame: function (code) {
      const youtube = "https://www.youtube.com/embed/"
      if(!code)
        return youtube

      const youtube2 = "https://youtu.be/"
      return code.indexOf(youtube) !== -1 ? code
          : (code.indexOf(youtube2) !== -1 ? code.replace(youtube2, youtube)
              : youtube + code)
    },
    statEntity: function () {
      nvgpost(this.$apiEndpoint+"/v1/entity_stat",
          "POST",
          {
            point: this.currentTable,
            data: this.subject
          },
          {}, (res)=>{
            if(res.data) {
              this.stat = res.data
            }
          }, true)
    },
    createEntity: function () {
      this.$store.dispatch("createTableData", {
        point: this.currentTable,
        data: this.entity,
        that: this,
      })
    },
    disableCollapse: function (fieldName) {
      this.collapsePlace(['collapse'], fieldName, 'max-h-0')
    },
    onAnkaOpened: function (file) {
      if(!file)
        this.ankaError("onAnkaOpened error")
    },
    ankaError: function (errorMessage) {
      console.log(errorMessage)
      this.$root.$emit("alert", "Error while uploading a file", 2)
    },
    onAnkaSaved: function (event, fieldName) {
      this.disableCollapse(fieldName)
      this.entity[fieldName] = event.croppedImageURI
    }
  }
}
</script>