var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sidebar'),_c('div',{staticClass:"relative md:ml-64 bg-blueGray-100"},[_c('admin-navbar'),_c('div',{staticClass:"px-2 md:px-4 lg:px-10 mx-auto w-full py-24"},[_c('div',{staticClass:"grid xl:grid-cols-2 gap-8"},[_c('div',{attrs:{"id":"ankaCropper"}},[_c('div',{staticClass:"inline-flex"},[_c('Button',{on:{"wasClicked":function($event){return _vm.collapsePlace(['collapse'], 'UploadCrop', 'max-h-0')}}},[_vm._v(" Upload cropped image ")])],1),_c('vue-anka-cropper',{staticClass:"w-full max-h-0 overflow-hidden transition-all mt-4",attrs:{"id":"collapseUploadCrop","options":{
                               aspectRatio: 1,
                               closeOnSave: true,
                               cropArea: 'circle',
                               croppedHeight: 400,
                               croppedWidth: 400,
                               cropperHeight: false,
                               frameLineDash: [5,3],
                               frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                               handleFillColor: 'rgba(255, 255, 255, 0.2)',
                               handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                               handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                               handleSize: 6,
                               handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                               layoutBreakpoint: 850,
                               maxCropperHeight: 768,
                               maxFileSize: 16000000,
                               overlayFill: 'rgba(0, 0, 0, 0.5)',
                               previewOnDrag: true,
                               previewQuality: 0.65,
                               resultQuality: 0.8,
                               resultMimeType: 'image/jpeg',
                               selectButtonLabel: 'Upload picture',
                               dropareaMessage: 'Select .png, .jpg or .jpeg file',
                               showPreview: false,
                               skin: 'weboa',
                               uploadData: {},
                               uploadTo: false}},on:{"cropper-error":_vm.ankaError,"cropper-file-selected":_vm.onAnkaOpened,"cropper-saved":function($event){return _vm.onAnkaSaved($event, 'UploadCrop')}}})],1),_c('div',{staticClass:"inline-flex items-start justify-start flex-col gap-4",attrs:{"id":"fullUploader"}},[(this.imagePreview)?_c('img',{staticClass:"inline-block w-1/2 select-none",attrs:{"src":this.imagePreview,"alt":"Preview"}}):_vm._e(),_c('Button',{staticClass:"border border-dashed tt-border-gray py-3 cursor-pointer\n              inline-flex items-center justify-center flex-col gap-2 relative"},[_c('span',[_vm._v("Upload without crop")]),_c('input',{staticClass:"cursor-pointer absolute top-0 z-10 left-0 w-full h-full opacity-0",attrs:{"type":"file","id":"fullUploaderInput","accept":"image/*"},on:{"change":function($event){return _vm.changeCallback($event, 'fullUploaderInput')}}})])],1)]),_c('h1',{staticClass:"text-4xl mt-16"},[_vm._v("All Image Storage")]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"grid xl:grid-cols-6 gap-6 mt-8"},_vm._l((_vm.images),function(image,indexImage){return _c('div',{key:'image'+indexImage,staticClass:"relative group"},[_c('img',{staticClass:"cursor-pointer object-cover",attrs:{"src":image.url,"alt":"Image"}}),_c('div',{staticClass:"group-hover:block hidden absolute top-0 left-0"},[_c('Button',{on:{"wasClicked":function($event){return _vm.trashImage(image.id)}}},[_vm._v("X")])],1)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }