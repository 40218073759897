<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <component :is="icon" />
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <component v-bind:is="action.click === 'url' ? 'router-link' :
                                                          (action.click === 'confirm' ? 'button' : 'div')"
        :to="action.click === 'url' ? action.data : false"
        @click="callConfirm(action.data.yesFunc, action.data.text)"
        v-for="(action, index) in actions" :key="'action'+index"
        class="text-sm py-2 px-4 font-normal block text-left w-full whitespace-nowrap bg-transparent hover:wb-text-green"
      >
        {{ action.name }}
      </component>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { DotsVerticalIcon, DotsCircleHorizontalIcon } from "@vue-hero-icons/outline"


export default {
  components: {
    DotsVerticalIcon, DotsCircleHorizontalIcon
  },
  props: {
    actions: {
      type: [Array, Boolean],
      default: () => [],
    },
    icon: {
      type: String,
      default: "DotsVerticalIcon"
    }
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      if(event)
        event.preventDefault()
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false
      } else {
        this.dropdownPopoverShow = true
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        })
      }
    },
    callConfirm: function (callback, text) {
      this.$store.commit("dataConfirm", {
        text: text,
        yesFunc: callback
      })
      this.$store.commit("toggleConfirm")
      this.toggleDropdown()
    }
  },
};
</script>
