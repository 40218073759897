<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <div class="px-2 md:px-4 lg:px-10 mx-auto w-full py-24">
        <div class="grid xl:grid-cols-2 gap-8">
          <div id="ankaCropper">
            <div class="inline-flex">
              <Button @wasClicked="collapsePlace(['collapse'], 'UploadCrop', 'max-h-0')">
                Upload cropped image
              </Button>
            </div>
            <vue-anka-cropper
                id="collapseUploadCrop"
                class="w-full max-h-0 overflow-hidden transition-all mt-4"
                :options="{
                                 aspectRatio: 1,
                                 closeOnSave: true,
                                 cropArea: 'circle',
                                 croppedHeight: 400,
                                 croppedWidth: 400,
                                 cropperHeight: false,
                                 frameLineDash: [5,3],
                                 frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                                 handleFillColor: 'rgba(255, 255, 255, 0.2)',
                                 handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                                 handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                                 handleSize: 6,
                                 handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                                 layoutBreakpoint: 850,
                                 maxCropperHeight: 768,
                                 maxFileSize: 16000000,
                                 overlayFill: 'rgba(0, 0, 0, 0.5)',
                                 previewOnDrag: true,
                                 previewQuality: 0.65,
                                 resultQuality: 0.8,
                                 resultMimeType: 'image/jpeg',
                                 selectButtonLabel: 'Upload picture',
                                 dropareaMessage: 'Select .png, .jpg or .jpeg file',
                                 showPreview: false,
                                 skin: 'weboa',
                                 uploadData: {},
                                 uploadTo: false}"
                @cropper-error="ankaError"
                @cropper-file-selected="onAnkaOpened"
                @cropper-saved="onAnkaSaved($event, 'UploadCrop')"></vue-anka-cropper>
          </div>

          <div id="fullUploader" class="inline-flex items-start justify-start flex-col gap-4">
            <img :src="this.imagePreview" alt="Preview" v-if="this.imagePreview"
                 class="inline-block w-1/2 select-none">
            <Button
                class="border border-dashed tt-border-gray py-3 cursor-pointer
                inline-flex items-center justify-center flex-col gap-2 relative">
              <span>Upload without crop</span>
              <input type="file" class="cursor-pointer absolute top-0 z-10 left-0 w-full h-full opacity-0"
                     id="fullUploaderInput" accept="image/*" @change="changeCallback($event, 'fullUploaderInput')">
            </Button>
          </div>
        </div>

        <h1 class="text-4xl mt-16">All Image Storage</h1>
        <div v-viewer class="grid xl:grid-cols-6 gap-6 mt-8">
          <div v-for="(image, indexImage) in images" :key="'image'+indexImage" class="relative group">
            <img :src="image.url" alt="Image" class="cursor-pointer object-cover">

            <div class="group-hover:block hidden absolute top-0 left-0">
              <Button @wasClicked="trashImage(image.id)">X</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Button from "@/components/Inter/Button";
import collapse from "@/helpers/collapse";
import vueAnkaCropper from 'vue-anka-cropper';
import nvgpost from "@/nvgpost";


export default {
  name: "ImageStorage",
  components: {
    Button, vueAnkaCropper,
    AdminNavbar, Sidebar
  },
  computed: {
    images: function () {
      return this.$store.state.images
    },
    countOfAnka: function () {
      return document.querySelectorAll(".ankaCropper__fileInput").length
    },
  },
  data() {
    return {
      imagePreview: false
    }
  },
  created() {
    this.$store.dispatch("getImages", {
      that: this,
    })
  },
  watch: {
    countOfAnka: function () {
      let ankas = document.querySelectorAll(".ankaCropper__fileInput")
      for (let i = 0; i < ankas.length; i++) {
        ankas[i].setAttribute("accept", ".jpg, .jpeg, .png")
      }
    }
  },
  methods: {
    collapsePlace: collapse.collapsePlace,
    disableCollapse: function (fieldName) {
      this.collapsePlace(['collapse'], fieldName, 'max-h-0')
    },
    changeCallback: function (event) {
      let selectedFiles = event.target.files
      for(let i = 0; i < selectedFiles.length; i++) {
        let selectedFile = selectedFiles[i]
        let reader = new FileReader()
        reader.onload = function(event) {
          if (event.target.result.match('image.*') || event.target.result.match('image/*'))
          {
            this.imagePreview = event.target.result
            this.onAnkaSaved({croppedImageURI: this.imagePreview})
          }
        }.bind(this)
        setTimeout(function(){
          reader.readAsDataURL(selectedFile)
        }.bind(this),150)
      }
    },
    onAnkaOpened: function (file) {
      if(!file)
        this.ankaError("onAnkaOpened error")
    },
    ankaError: function (errorMessage) {
      console.log(errorMessage)
      this.$root.$emit("alert", "Error while uploading a file", 2)
    },
    onAnkaSaved: function (event, fieldName) {
      if(fieldName)
        this.disableCollapse(fieldName)

      nvgpost(this.$apiEndpoint+"/v1/images",
          "POST",
          {
            image: event.croppedImageURI,
          },
          {}, (res)=>{
            if(res.data) {
              this.imagePreview = false
              this.$store.commit("setImages", res.data)
              this.$root.$emit("alert", "Image uploaded successfully!", 1)
            }
            else {
              this.$root.$emit("alert", "Image upload Error", 2)
            }
          }, true)
    },
    trashImage: function (id) {
      this.$store.commit("toggleConfirm")
      this.$store.commit("dataConfirm", {
        text: "Delete image",
        yesFunc: function () {
          this._trashImage(id)
          this.$store.commit("toggleConfirm")
        }.bind(this)
      })
    },
    _trashImage: function (id) {
      nvgpost(this.$apiEndpoint+"/v1/images",
          "DELETE",
          {
            id: id,
          },
          {}, (res)=>{
            if(res.data) {
              this.$store.commit("setImages", res.data)
              this.$root.$emit("alert", "Image deleted successfully!", 1)
            }
            else {
              this.$root.$emit("alert", "Image delete Error", 2)
            }
          }, true)
    }
  }
}
</script>